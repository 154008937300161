body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #212121 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-switch {
  height: 1.7rem !important;
  background-image: linear-gradient(to right, rgb(109, 180, 238), rgb(39, 38, 105)),
    linear-gradient(to right, rgb(68, 226, 29), rgb(255, 255, 255)) !important;
  border: 1px solid #d1ecfd !important;
}

.ant-switch-checked {
  height: 1.7rem !important;
  border: 1px solid #d1ecfd !important;
}

.ant-switch-handle {
  top: 2.5px !important;
  left: 3px !important;
  height: 19px !important;
  width: 20px !important;
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 5px) !important;
}

.ant-switch-handle:before {
  background-color: #d1ecfd !important;
}

.skills-items .data {
  margin-top: 1rem;
}

.skills-items .data h2 {
  text-transform: uppercase;
}

.imgContainer {
  display: flex;
  justify-content: center;
}

.gl-effect {
  width: 60px;
  position: absolute;
  top: 70px;
  z-index: 9;
  left: 62px;
  height: 22px;
  opacity: 0;
  transition: all 300ms ease;
}

.show {
  opacity: 1;
  transition: all 300ms ease;
}

.banner {
  position: absolute;
  top: -100%;
  transition: all 300ms ease;
  left: -10%;
  transform: rotate(-31deg);
  z-index: 9;
  border-radius: 0.5rem;
  position: absolute;
  top: -100%;
  transition: all 300ms ease;
  left: -16%;
  transform: rotate(-31deg);
  z-index: 9;
  padding: 5px 10px;
  font-weight: 700;
}

.banner-show {
  transition: all 300ms ease;
  top: 15px;
}
